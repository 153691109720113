<template>
  <div
    :class="[
      'bg-white rounded-lg w-full h-28 flex items-center justify-between border border-neutral-50 duration-300 ease-linear',
      { 'border-bb-brand-purple': hover },
    ]"
  >
    <div
      class="px-6 py-4 space-y-4 w-full h-full border-r border-neutral-50"
      @click="selectMonitoringGroup"
    >
      <div class="flex justify-between">
        <div class="flex items-center gap-4">
          <h4 class="h4">{{ monitoringGroup.name }}</h4>
          <p
            class="px-2 py-0.5 rounded-md font-bold"
            :class="isBrandedMonitoring ? 'blue-bg-3 blue-text-color' : 'red-bg-3 red-text-color'"
          >
            {{ isBrandedMonitoring ? 'Brand' : 'Generic' }}
          </p>
          <p
            v-if="isPaused"
            class="px-2 py-0.5 rounded-md font-medium bg-bb-warning-100 text-bb-warning-200 flex align-center gap-1"
          >
            <ic-pause
              size="16"
              class="my-auto"
            />
            Paused
          </p>
        </div>
      </div>
      <div class="flex divide-x flex-nowrap">
        <div
          v-if="isBrandedMonitoring"
          class="flex items-center gap-2 w-auto whitespace-no-wrap pr-6"
        >
          <ic-campaign
            class="text-bb-cool-grey mb-1"
            :size="24"
          />
          <p class="text-sm text-bb-text-default">{{ monitoringGroup.campaignName }}</p>
        </div>

        <div :class="['flex items-center pr-6', isBrandedMonitoring && 'pl-6']">
          <ic-pin
            solid
            class="text-bb-cool-grey"
            :size="24"
          />

          <base-dropdown
            :disabled="!readOnly"
            :options="monitoringLocations || []"
            :show-dropdown-arrow="readOnly"
            :selected-item="selectedLocation ?? monitoringLocations[0]"
            trigger-style="h-8 w-full min-w-40 rounded-lg flex items-center px-2 bg-white border-1  hover:bg-neutral-0 duration-300 cursor-pointer ease-linear whitespace-no-wrap"
            dropdown-style="border border-neutral-100 mt-10 min-w-full max-w-fit max-h-48 rounded-lg bg-white shadow-sm"
            @select-item="selectLocation"
          >
            <template #trigger="{ selectedItem }">
              <div class="h-full w-full pr-5 flex items-center gap-2">
                <div class="w-4 h-4 rounded-sm overflow-hidden">
                  <img
                    :src="`/content/flags/${selectedItem.value?.toLowerCase()}.svg`"
                    class="w-full h-full object-cover"
                  />
                </div>
                <p>{{ selectedItem?.label }}</p>
              </div>
            </template>

            <template #option="{ optionData }">
              <div
                class="h-full w-full p-2 flex items-center gap-2 hover:bg-bb-secondary-purple rounded-md duration-300 ease-linear cursor-pointer"
              >
                <div class="w-4 h-4 rounded-sm overflow-hidden">
                  <img
                    :src="`/content/flags/${optionData.value?.toLowerCase()}.svg`"
                    class="w-full h-full object-cover"
                  />
                </div>
                <p>{{ optionData?.label }}</p>
              </div>
            </template>
          </base-dropdown>
        </div>

        <div class="px-6 flex items-center gap-2 w-full font-medium">
          <div class="transform -rotate-45">
            <ic-key-solid
              class="text-bb-cool-grey"
              :size="24"
            />
          </div>
          <proto-chips
            :key="monitoringGroup.id"
            :chips="monitoringKeywords"
            :keyword-query="keywordQuery"
          >
            <template #chip="{ chip }">
              <div
                class="py-1 px-2 rounded-md duration-300 ease-linear cursor-pointer"
                :class="
                  chip.selected || !readOnly
                    ? 'border border-bb-purple-100 bg-bb-secondary-purple text-bb-brand-purple'
                    : 'border border-white hover:bg-bb-secondary-purple bg-neutral-0'
                "
                @click="selectKeyword(chip)"
              >
                <p class="text-sm whitespace-no-wrap">{{ chip.label }}</p>
              </div>
            </template>

            <template #dropdown="{ hiddenChips, hiddenCount }">
              <base-dropdown
                position="right-0 top-0"
                :show-dropdown-arrow="false"
                :options="hiddenChips"
                trigger-style="h-8 w-8 rounded-lg flex items-center justify-center bg-bb-secondary-purple border border-bb-purple-100 hover:bg-bb-brand-purple hover:text-white duration-300 cursor-pointer ease-linear"
                dropdown-style="border border-neutral-100 mt-10 min-w-50  w-auto max-h-50 rounded-lg bg-white shadow-sm py-1 px-2 space-y-1"
                @select-item="selectKeyword"
              >
                <template #trigger>
                  <div class="h-full w-full flex items-center justify-center">
                    <p class="text-sm text-bb-brand-purple">+{{ hiddenCount }}</p>
                  </div>
                </template>

                <template #dropdown-component>
                  <text-input
                    v-if="hiddenCount >= 5"
                    v-model="keywordQuery"
                    placeholder="Search Keyword"
                    class="py-1"
                    input-name="dropdown-search"
                    input-id="dropdown-search"
                  />
                </template>

                <template #option="{ optionData }">
                  <div
                    class="h-8 w-full flex items-center gap-2 hover:bg-bb-secondary-purple rounded-md duration-300 ease-linear cursor-pointer"
                    :class="
                      optionData.selected || !readOnly
                        ? 'border border-bb-purple-100 bg-bb-secondary-purple text-bb-brand-purple'
                        : 'border border-white bg-neutral-0'
                    "
                  >
                    <p class="whitespace-no-wrap px-2 truncate max-w-40">{{ optionData?.label }}</p>
                  </div>
                </template>
              </base-dropdown>
            </template>
          </proto-chips>
        </div>
      </div>
    </div>
    <div
      v-if="rightButton"
      class="flex items-center text-bb-default px-6 h-full hover:bg-neutral-0 duration-300 ease-linear hover:text-bb-brand-purple cursor-pointer rounded-r-lg"
      @click="openModal"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div>
        <slot name="right-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import '@/views/site/search/competitor_monitoring_v2/styles/style.css'
import IcCampaign from '@/components/icon/brightbid/ic-campaign.vue'
import IcPin from '@/components/icon/brightbid/ic-pin.vue'
import IcKeySolid from '@/components/icon/ic-key-solid.vue'
import IcPause from '@/components/icon/brightbid/ic-pause.vue'

import ProtoChips from '@/components/chips/ProtoChips.vue'
import BaseDropdown from '@/components/dropdown/BaseDropdown.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'

export default {
  name: 'MonitoringGroupCard',
  components: {
    IcCampaign,
    IcPin,
    IcKeySolid,
    IcPause,
    BaseDropdown,
    ProtoChips,
    TextInput,
  },
  props: {
    monitoringGroup: {
      type: Object,
      default: () => ({}),
    },
    rightButton: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLocation: null,
      selectedKeywords: [],
      keywordQuery: '',
      hover: false,
    }
  },
  computed: {
    monitoringKeywords() {
      return this.monitoringGroup.keywords.map(_ => {
        return {
          label: _.keyword,
          value: _.keyword,
          selected: this.selectedKeywords.includes(_.keyword),
        }
      })
    },
    monitoringLocations() {
      return this.monitoringGroup.geoLocations.map(_ => {
        return {
          label: _.name,
          value: _.countryCode,
        }
      })
    },
    isBrandedMonitoring() {
      return this.monitoringGroup?.type === 'brand'
    },
    isPaused() {
      return !this.monitoringGroup?.initialized
    },
  },
  mounted() {
    this.selectedKeywords = this.monitoringKeywords.map(_ => _.value)
  },
  methods: {
    openModal() {
      this.$emit('open-modal', this.monitoringGroup)
    },
    selectLocation(location) {
      this.selectedLocation = location
      this.$emit('select-location', location.label)
    },
    selectKeyword(keyword) {
      if (!this.readOnly) return
      this.keywordQuery = ''
      if (keyword.selected) {
        this.selectedKeywords = this.selectedKeywords.filter(_ => _ !== keyword.value)
        this.$emit('select-keyword', this.selectedKeywords)
        return
      }
      this.selectedKeywords.push(keyword.value)
      this.$emit('select-keyword', this.selectedKeywords)
    },
    selectMonitoringGroup() {
      this.$emit('select-monitoring-group', this.monitoringGroup)
    },
  },
}
</script>

<style scoped lang="scss"></style>
